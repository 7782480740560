:root {
  --width: 30vmin;
}

.circles {
  margin: 20px;
  width: var(--width);
  height: var(--width);
  max-width: 100px;
  max-height: 100px;
  position: top;
  perspective: 50000px;
  color: #ffffff;
}

.circles div {
  position: absolute;
  width: 100%;
  height: 100%;
  border-left: calc(var(--width) / 25) solid;
  border-top: calc(var(--width) / 25) solid transparent;
  border-radius: 100%;
}

.circles div:nth-child(1) {
  animation: anim-1 3s linear infinite;
}

.circles div:nth-child(2) {
  animation: anim-2 3s linear infinite;
}

.circles div:nth-child(3) {
  animation: anim-3 3s linear infinite;
}

@keyframes anim-1 {
  from {
    transform: rotateZ(calc(360deg / 3 * 1)) rotateX(66deg) rotateZ(0deg);
  }
  to {
    transform: rotateZ(calc(360deg / 3 * 1)) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes anim-2 {
  from {
    transform: rotateZ(calc(360deg / 3 * 2)) rotateX(66deg) rotateZ(0deg);
  }
  to {
    transform: rotateZ(calc(360deg / 3 * 2)) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes anim-3 {
  from {
    transform: rotateZ(calc(360deg / 3 * 3)) rotateX(66deg) rotateZ(0deg);
  }
  to {
    transform: rotateZ(calc(360deg / 3 * 3)) rotateX(66deg) rotateZ(360deg);
  }
}

.navButtons {
  display: none;
}

@media (min-width: 600px) {
  .navButtons {
    display: flex;
  }
  .MuiIconButton-root {
    display: none;
  }
}
